const About_me = () => {
    return <>
        <figure>
            <img src="img/about-me/selfie.jpg" alt="" className='selfie' width={400} />
        </figure>
        <article>
            <h4>Dorian Sotomayor, <i>rexmalebka</i></h4>
            <p>I'm a digital artist interested in art made by computers and interdisciplinary art.
                <br />I currently work as a DevOps engineer, making my way through frontend development aiming to become a fullstack dev.
                <br />I've been a GNU / Linux user since a long time ago and got a free software and open source politic on all the software I code and consume.
            </p>
            <p>
                <br />I love JavaScript and use it as backend and frontend. I usually create web servers, websockets implementations and lately webrtc.I made this site as a showcase of my work throught the years and a way of coding art into the web, please feel free to explore it and contact me if anything it's broken.
                <br />I made this site as a showcase of my work throught the years and a way of coding art into web, please feel free to explore it and contact me if anything it's broken.
            </p>
            <p>
                <br />I love making experimental music using Supercollider language. If you're interested, please check my bandcamp.
            </p>
        </article>

        <section className='links'>
            <figure>
                <img src="/img/selfie.png" alt="selfie" width={100} />
                <figcaption>work e-mail: <a href="mailto:dorian.velsot@gmail.com">dorian.velsot@gmail.com</a></figcaption>
            </figure>
            <figure>
                <img src="/img/selfie.png" alt="selfie" width={100} />
                <figcaption>casual e-mail: <a href="mailto:rexmalebka@krutt.org">rexmalebka@krutt.org</a></figcaption>
            </figure>
            <figure>
                <img src="/img/about-me/bandcamp.jpg" alt="bandcamp icon" width={100} />
                <figcaption>bandcamp: <a href="https://rexmalebka.bandcamp.com/">rexmalebka</a></figcaption>
            </figure>
            <figure>
                <img src="/img/about-me/github.jpg" alt="github icon" width={100} />
                <figcaption>github: <a href="https://github.com/rexmalebka">rexmalebka</a></figcaption>
            </figure>
            <figure>
                <img src="/img/about-me/instagram.jpg" alt="instagram icon" width={100} />
                <figcaption>instagram: <a href="https://www.instagram.com/rexmalebka/">rexmalebka</a></figcaption>
            </figure>
        </section>
    </>
}

export default About_me