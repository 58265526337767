import { useEffect, useState, useMemo, useCallback, useRef } from 'react'


type exhib = {
    expo: string
    title: string
    date: string
    place: string
    thumbnail: string
}

const Exhibitions = () => {
    const [selected_year, set_selected_year] = useState<`${number}` | undefined>(undefined)
    const [selected_month, set_selected_month] = useState<`${number}`>()

    const exhibitions = useMemo(() => {
        const exhibition_list = [
            {
                "expo": "¿Amor? vol.2",
                "title": "duelos y quebrantos",
                "date": "2024-03-24T06:00:00.000Z",
                "place": "Museo Universitario Fernando del Paso, Colima",
                "description": "",
                "thumbnail": "/img/exhibitions/duelos-quebrantos/img_1.jpg"
            }, {
                "expo": "Prototipos tecnonaturales",
                "title": "Limulus: organismos en el antropoceno",
                "date": "2023-07-09T06:00:00.000Z",
                "place": "Pinacoteca Universitaria, Colima",
                "description": "",
                "thumbnail": "/img/exhibitions/limulus/img_1.jpg"
            },
            {
                "expo": "Estaba soñando y no lo sabía",
                "title": "meta hotline",
                "date": "2023-03-18T06:00:00.000Z",
                "place": "El edificio, Colima",
                "description": "",
                "thumbnail": "/img/exhibitions/meta-hotline/img_1.jpg"
            },
            {
                "expo": "Algoreven",
                "title": "livecoding show",
                "date": "2022-08-02T05:00:00.000Z",
                "place": "Edificio Indianilla, Cdmx",
                "description": "",
                "thumbnail": "/img/exhibitions/algoreven/img_1.jpg"
            },
            {
                "expo": "Edges",
                "title": "Contemplación del fin del mundo",
                "date": "2020-12-19T06:00:00.000Z",
                "place": "CENART",
                "description": "",
                "thumbnail": "/img/exhibitions/edges-contemplacion/promo.jpg"
            },
            {
                "expo": "Algorave",
                "title": "livecoding show",
                "date": "2023-07-03T06:00:00.000Z",
                "place": "cdmx",
                "description": "",
                "thumbnail": "/img/exhibitions/algorave/showcase.jpg"
            }
            
        ]

const av_dates: {
    [year: `${number}`]: {
        [month: `${number}`]: {
            [day: `${number}`]: exhib[]
        }
    }
} = {}

exhibition_list.forEach((exhib, exhib_index) => {
    const date = new Date(exhib.date)

    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()

    if (!av_dates[`${year}`]) {
        av_dates[`${year}`] = {}
    }

    if (!av_dates[`${year}`][`${month}`]) {
        av_dates[`${year}`][`${month}`] = {}
    }
    if (!av_dates[`${year}`][`${month}`][`${day}`]) {
        av_dates[`${year}`][`${month}`][`${day}`] = []
    }

    av_dates[`${year}`][`${month}`][`${day}`].push(exhib)

})

return av_dates
    }, [selected_year, selected_month])

const select_year = useCallback((year: `${number}`) => {
    if (year == selected_year) {
        set_selected_year(undefined)
        return
    }
    set_selected_year(year)

}, [selected_year])

const select_month = useCallback((month: `${number}`) => {
    if (month == selected_month) {
        set_selected_month(undefined)
        return
    }
    set_selected_month(month)

}, [selected_month])

return <>
    <article className='win98-panel__main__exhibitions__dates'>
        <label htmlFor="year">year: </label>
        {
            Object.keys(exhibitions).map((year: `${number}`) => (
                <label htmlFor={year}
                    className={selected_year == year ? 'win98-panel__main__exhibitions__dates--year--active' : 'win98-panel__main__exhibitions__dates--year--inactive'}
                    onPointerDown={() => select_year(year)}
                >{year}</label>
            ))
        }
        {selected_year && (<label htmlFor="month">month: </label>)}

        {
            exhibitions[selected_year] && (
                Object.keys(exhibitions[selected_year]).map((month: `${number}`) => (

                    <label htmlFor={month}
                        className={selected_month == month ? 'win98-panel__main__exhibitions__dates--month--active' : 'win98-panel__main__exhibitions__dates--month--inactive'}
                        onPointerDown={() => select_month(month)}
                    >{month}</label>
                ))
            )
        }
    </article>
    {
        Object.keys(exhibitions).filter(year => (
            (!selected_year) ? true : (selected_year == year)
        )).sort().map((year: `${number}`) => (
            Object.keys(exhibitions[year]).filter(month => (
                (!selected_month) ? true : (selected_month == month)
            )).sort().map((month: `${number}`) => (
                Object.keys(exhibitions[year][month]).sort().map((day: `${number}`) => (
                    exhibitions[year][month][day].map(exhib => (
                        <div className='win98-panel__main__exhibitions__exhib'>
                            <h3>{exhib.title}</h3>
                            <h4>{exhib.expo}</h4>
                            <label htmlFor="exhib date" className='win98-panel__main__exhibitions__exhib__date'>{`${day}-${month}-${year}`}</label>
                            <label htmlFor="exhib place">{exhib.place}</label>
                            <img src={exhib.thumbnail} alt="" />
                        </div>
                    ))
                ))
            ))
        ))
    }
</>
}

export default Exhibitions