import { render } from 'react-dom'
import { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";

import { IoMdGlobe } from "@react-icons/all-files/io/IoMdGlobe";

import { Canvas, useFrame, useThree } from '@react-three/fiber'

import './css/win98.css'
import './css/style.css'
import './css/3d_visuals.css'

import About_me from './about_me';
import Projects from './projects';
import Exhibitions from './exhibitions'

const Scene = () => {
    const { scene, gl, } = useThree()
    const [rot, set_rot] = useState([0, 0, 0])
    const [p, set_p] = useState(1)
    const [q, set_q] = useState(3)


    useFrame(({ clock }) => {

        set_rot(r => [
            0.1 + clock.getElapsedTime() * 0.4,
            0.2 + clock.getElapsedTime() * 0.35,
            clock.getElapsedTime() * 0.35,
        ])

        set_p(1 + Math.sin(clock.getElapsedTime() * 0.025) * 20)
        set_q(3 + (Math.sin(clock.getElapsedTime() * 0.025) * 20) % 10)
    })

    useEffect(() => {
        console.debug("scene", scene, gl)
    }, [])

    return <>
        <mesh scale={[20, 20, 20]} rotation={rot}>
            <torusKnotGeometry args={[20, 10, 64, 8, p, q]} />
            <meshNormalMaterial transparent opacity={0.75} wireframe wireframeLinewidth={2} />
        </mesh>
    </>

}

const App = () => {
    const [page, set_page] = useState<'about-me' | 'projects' | 'exhibitions'>('about-me')
    const [win98_visible, set_win98_visible] = useState(true)

    const [win98_title_clicked, set_win98_title_clicked] = useState(false)
    const [win98_pos, set_win98_pos] = useState({ x: 0, y: 0 })
    const [win98_offset, set_win98_offset] = useState({ x: 0, y: 0 })

    const win98_panel = useRef<HTMLDivElement>(null)

    const [win98_party_mode, set_win98_party_mode] = useState(false)

    const handle_win98_pointerdown = useCallback((evt: React.PointerEvent<HTMLElement>) => {
        if (!win98_panel.current) return

        set_win98_title_clicked(true)

        const rect = win98_panel.current.getBoundingClientRect();

        set_win98_offset({
            x: evt.clientX - rect.left,
            y: evt.clientY - rect.top

        })

    }, [win98_panel])

    const move_win98 = useCallback((evt: PointerEvent) => {

        if (!win98_title_clicked || !win98_panel.current) return

        set_win98_pos({
            x: evt.clientX - win98_offset.x,
            y: evt.clientY - win98_offset.y,
        })
    }, [win98_title_clicked, win98_panel, win98_offset])


    useEffect(() => {
        if (!win98_panel.current) return

        const rect = win98_panel.current.getBoundingClientRect();

        set_win98_pos({
            x: window.innerWidth / 2 - rect.width / 2,
            y: 10,
        })
    }, [win98_panel])

    useEffect(() => {
        if (!win98_panel.current) return

        const handle_win98_pointerup = () => {
            set_win98_title_clicked(false)
        }

        document.body.addEventListener('pointerup', handle_win98_pointerup)
        document.body.addEventListener('pointermove', move_win98)

        return () => {
            document.body.removeEventListener('pointerup', handle_win98_pointerup)
            document.body.removeEventListener('pointermove', move_win98)
        }

    }, [win98_panel, win98_title_clicked])

    return (
        <>
            <figure className='win98-icon' onClick={() => set_win98_visible(true)}>
                <img src="/img/selfie.png" alt="" width={100} />
                <figcaption>rexmalebka.com</figcaption>
            </figure>
            <figure className='win98-icon' onClick={() => set_win98_party_mode(t => !t)}>
                <img src="/img/party_mode.png" alt="" width={100} />
                <figcaption>party mode! [{win98_party_mode ? 'on' : 'off'}]</figcaption>
            </figure>

            <div className={win98_visible ? "win98-panel" : 'win98-panel win98-panel--invisible'}
                style={{
                    left: win98_pos.x, top: win98_pos.y
                }}
                ref={win98_panel}
            >
                <header className='win98-panel__header'
                    onPointerDown={handle_win98_pointerdown}
                >
                    <div className='win98-panel__title'>
                        <IoMdGlobe></IoMdGlobe> rexmalebka.com
                    </div>
                    <IoMdClose onClick={() => {
                        console.debug("AAAAAAAAAAAAAAAAAAAAA")
                        set_win98_visible(false)
                    }
                    } />
                </header>
                <nav className='win98-panel__nav'>
                    <div className={page == 'about-me' ? 'win98-panel__tab win98-panel__tab-active' : 'win98-panel__tab'} onClick={() => set_page('about-me')}>About me</div>
                    <div className={page == 'projects' ? 'win98-panel__tab win98-panel__tab-active' : 'win98-panel__tab'} onClick={() => set_page('projects')}>Online projects</div>
                    <div className={page == 'exhibitions' ? 'win98-panel__tab win98-panel__tab-active' : 'win98-panel__tab'} onClick={() => set_page('exhibitions')}>Art exhibitions</div>

                </nav>
                <main className='win98-panel__main'>
                    {
                        page == 'about-me' && <About_me />
                    }
                    {
                        page == 'projects' && <Projects />
                    }
                    {
                        page == 'exhibitions' && <Exhibitions/>
                    }
                </main>
            </div >
            {win98_party_mode &&
                <div className='visuals-3d'>
                    <Canvas style={{ pointerEvents: 'none' }} ref={(ref) => console.debug(ref, 'canvas')}>
                        <ambientLight intensity={Math.PI / 2} />
                        <Scene></Scene>

                    </Canvas>
                </div>}
        </>
    )
}

render(<App></App>, document.querySelector("#app"))