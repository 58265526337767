import { useEffect, useState, useMemo, useCallback, useRef } from "react";

import { IoMdSunny } from "@react-icons/all-files/io/IoMdSunny";
import { IoMdMoon } from "@react-icons/all-files/io/IoMdMoon";
import { IoMdTime } from "@react-icons/all-files/io/IoMdTime";
import { IoIosClose } from "@react-icons/all-files/io/IoIosClose";

const Projects = () => {
  const [healthchecks_id, set_healthchecks_id] = useState([]);
  const [healthchecks, set_healthchecks] = useState([]);
  const [tags, set_tags] = useState(new Set<string>());
  const [selected_tags, set_selected_tags] = useState(new Set<string>());

  const projects = useMemo(
    () => [
      {
        project: "WebRTC video streaming",
        description: (
          <>
            <div>
              The P2P Video Streamer is a React-based application using PeerJS
              for peer-to-peer video streaming without an intermediary server.
              It supports HTTPS, offers host and stream modes, and enables video
              sharing via QR codes.
            </div>
            <a href="https://github.com/rexmalebka/p2p-video-streamer">
              github repo
            </a>
          </>
        ),
        tags: ["react.js", "webrtc", "HTML5", "multimedia", "p2p"],
        url: "/p2p-video-streamer",
      },
      {
        project: "video looper",
        description: (
          <>
            <div>
              The Video Looper is a tool for recording and playing video loops
              from webcam or screen share inputs, useful for live visuals. It
              supports loop playback with adjustable rates and various key
              shortcuts for controls and MIDI controls.
            </div>
            <a href="https://github.com/rexmalebka/video_looper">github repo</a>
          </>
        ),
        tags: ["react.js", "HTML5", "multimedia", "MIDI"],
        url: "/video-loooper",
      },
      {
        project: "serverless image Gallery",
        description: (
          <>
            <div>
              P2P image-sharing gallery built with React, utilizing WebRTC and
              the File API. It allows users to share images directly between
              browsers without a central server. The project requires browsers
              that support the File System API, it may face NAT issues for peer
              connections.
            </div>
            <a href="https://github.com/rexmalebka/serverless-gallery">
              github repo
            </a>
          </>
        ),
        url: "/p2p-gallery",
        tags: ["react.js", "webrtc", "HTML5", "multimedia", "p2p"],
      },
      // {
      //     project: 'WebRTC broadcaster',
      //     description: 'web tool alike OBS that allows the creation of scenes, mixing different video feeds on a webrtc streaming service.',
      //     tags: ['react.js', 'HTML5', 'multimedia', 'MIDI']
      // },
      {
        project: "isla",
        description:
          "3D island made with threejs for live concerts and online gatherings.",
        url: "/isla",
        tags: ["react.js", "three.js", "webrtc", "HTML5"],
      },
      {
        project: "rtmp services",
        description:
          "This site is hosting an rtmp server server, if you want to use it for art projects please send me an email",
        tags: ["rtmp", "server"],
      },
    ],
    []
  );

  useEffect(() => {
    if (!projects) return;

    const hc_ids = projects.map((project, i) =>
      setInterval(() => {
        fetch(project.url).then((response) => {
          console.debug("checking", project.url, response.ok);
          set_healthchecks((hcs) => [
            ...hcs.slice(0, i),
            response.ok,
            ...hcs.slice(i + 1),
          ]);
        });
      }, 5000)
    );

    set_tags(new Set(projects.map((p) => p.tags).flat()));
    set_selected_tags(new Set(projects.map((p) => p.tags).flat()));

    return () => {
      hc_ids.map((hc_id) => {
        clearInterval(hc_id);
      });
    };
  }, [projects]);

  return (
    <>
      <article className="win98-panel__main__projects__tags">
        {[...tags].map((tag) => (
          <label
            className={
              selected_tags.has(tag)
                ? "win98-panel__main__projects__tags--tag--active"
                : "win98-panel__main__projects__tags--tag--inactive"
            }
            onPointerDown={() => {
              set_selected_tags((sel_tags) => {
                if (!sel_tags.delete(tag)) {
                  sel_tags.add(tag);
                }
                return new Set(sel_tags);
              });
            }}
          >
            {tag}
          </label>
        ))}
      </article>
      {projects
        .filter((proj) => proj.tags.some((tag) => selected_tags.has(tag)))
        .map((project, i) => (
          <article className="win98-panel__main__projects__project">
            <h4>{project.project}</h4>
            <div>{project.description}</div>
            <div
              className={
                healthchecks[i]
                  ? "projects__project-status--online"
                  : "projects__project-status--offline"
              }
            >
              {healthchecks[i] == undefined ? (
                ["testing availability", <IoMdTime />]
              ) : healthchecks[i] ? (
                <>
                  <a href={project.url}>online</a>
                  <IoMdSunny />
                </>
              ) : (
                ["offline", <IoMdMoon />]
              )}
            </div>
          </article>
        ))}
    </>
  );
};

export default Projects;
